


































// --- Vue & Template imports ---
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AwaitingApprovalPage extends Vue {

  beforeMount() {
    // this is to check if the AppBar should display the Edit button or not
    this.$root.$emit('update:appBar', this.$route.name);
  }

  getWidthForBreakpoint(breakpoint: string) {
    switch (breakpoint) {
      case 'xl':
      case 'lg':
        return '50%';

      case 'md':        
        return '60%';

      default:
        return '90%';
    }
  }

}
